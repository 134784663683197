import '@ungap/global-this';
import 'core-js/stable/string/match-all';
import { enableES5 } from 'immer';
import ResizeObserver from 'resize-observer-polyfill';

if (!Math.trunc) {
  Math.trunc = function (v) {
    return v < 0 ? Math.ceil(v) : Math.floor(v);
  };
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

enableES5();

if (!window.ResizeObserver) window.ResizeObserver = ResizeObserver;
