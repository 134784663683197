/**
 * @param {ErrorEvent} e
 */
function omitResizeObserverError(e) {
  if (
    e.message.includes('ResizeObserver loop completed with undelivered notifications') ||
    e.message.includes('ResizeObserver loop limit exceeded')
  ) {
    e.stopImmediatePropagation();
  }
}
window.addEventListener('error', omitResizeObserverError);
